<template>
<div class="ticket">
    <center>
        <img :src="'/logo/logo_brayan4.png'" alt="Logo" style="width:50px;height:50px;margin-bottom:15px">
    </center>
    <div class="centered">STRUK PEMBELANJAAN
        <br>
        {{ print_value.invoice }}
        <br>
        Status: {{ print_value.iscash == 1 ? 'Cash': print_value.iscash == 2 ? 'Debit' : 'Hutang' }}
        <br>
        <a v-if="print_value.iscash == 2">{{ print_value.bankDebit+' - '+print_value.jenisDebit }}</a>
</div>
    
    -------------------------------------------------
    <table style="border:0px">
        <tbody style="border:0px">
            <tr style="border:0px">
                <td style="border:0px">Kasir: {{ print_value.nama_kasir }},  </td>
                <td style="border:0px"> Pembeli: {{ print_value.nama_pembeli }}</td>
            </tr>
        </tbody>
    </table>
-------------------------------------------------
    <div style="margin-top:4px" v-for="(p1, index) in print_value.detail_transaksi" :key="index">

        <div style="margin-top:4px">{{ p1.barang.nama_barang.toUpperCase()}}</div>

        <table style="border:0px;margin-top:0px">
            <tbody style="border:0px">
                <tr style="border:0px">
                    <td class="quantity" style="border:0px">{{ "x"+p1.qty }}</td>
                    <td class="price" style="border:0px"> {{ "@" + parseInt(p1.harga_fix*((100-p1.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</td>
                    <td class="price1" style="border:0px"> {{ceksubtotal(index).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</td>
                </tr>
            </tbody>
        </table>

    </div>
    -------------------------------------------------
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td style="border:0px" class="description">TOTAL</td>
                <!-- <td class="price"></td> -->
                <td style="border:0px" class="price1"> {{ cektotal().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td  style="border:0px" class="description">POTONGAN</td>
                <!-- <td class="price"></td> -->
                <td  style="border:0px" class="price1"> {{ print_value.potongan.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
              
            </tr>

        </tbody>
    </table>
    <table v-if="print_value.iscash == 2" style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td v-if="print_value.iscash" style="border:0px" class="description">EDC/TF</td>
                <td v-else style="border:0px" class="description">PEMBAYAR {{print_value.log_hutang.length}}</td>
                <!-- <td class="price"></td> -->
                <td v-if="print_value.iscash" style="border:0px" class="price1"> {{ print_value.bayar_edc.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                <td v-else style="border:0px" class="price1"> {{ cekloghutang().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td v-if="print_value.iscash" style="border:0px" class="description">TUNAI</td>
                <td v-else style="border:0px" class="description">PEMBAYAR {{print_value.log_hutang.length}}</td>
                <!-- <td class="price"></td> -->
                <td v-if="print_value.iscash" style="border:0px" class="price1"> {{ print_value.bayar.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                <td v-else style="border:0px" class="price1"> {{ cekloghutang().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td v-if="print_value.iscash" style="border:0px" class="description">KEMBALIAN</td>
                <td v-else style="border:0px" class="description">KURANG</td>
                <!-- <td class="price"></td> -->
                <td v-if="print_value.iscash==1" style="border:0px" class="price1"> {{ (print_value.bayar-cektotal()+print_value.potongan).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                <td v-else-if="print_value.iscash==2" style="border:0px" class="price1"> {{ ((print_value.bayar+print_value.bayar_edc+print_value.potongan)-cektotal()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                <td v-else style="border:0px" class="price1"> {{ (cekloghutang()+print_value.potongan-cektotal()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>

    <br>
    <p class="centered">{{ filterdate(print_value.updated_at) }}<br>Thanks for your purchase!
        <br>Brayan Diesel  </p>
            
    <button id="btnPrint" class="hidden-print btn btn-primary text-white" @click="printed">Print</button>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            //state loggedIn with localStorage
            loggedIn: localStorage.getItem('loggedIn'),
            //state token
            token: localStorage.getItem('token'),
            //state user logged In
            print_value: [],
            jumtransaksi: 0,
            jumpendapatan: 0,
            diskon: 0,
            edcNominal: 0,
            link: this.globalApiUrl,
        }
    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            console.log(this.token);
            let data = this.$route.query.data;
            axios.get(this.link + 'api/transaksi-print?inv=' + data, {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response);
                this.print_value = response.data.data;
                console.log(this.print_value);
                // assign response to state user
            }).catch(error => {
                window.opener = self;
                window.close();
                setTimeout(function () {
                    window.close();
                }, 800);
            });

        }

    },
    mounted() {
        // print();
    },
    methods: {
        printed() {
            print();
        },
        filterdate(a) {
            // console.log(a);
            return moment(String(a)).format('h:mm:ss DD/MM/YYYY')
        },
        ceksubtotal(index) {
            var harga_fix = 0;
            harga_fix = harga_fix + (parseInt(this.print_value.detail_transaksi[index].harga_fix*((100-this.print_value.detail_transaksi[index].diskon)/100)) * this.print_value.detail_transaksi[index].qty)
            return harga_fix;
        },
        cektotal() {
            var harga_fix = 0;
            //  this.diskon = 0;
            console.log(this.print_value.detail_transaksi);
            if(this.print_value.detail_transaksi){
                this.print_value.detail_transaksi.forEach(i => {
                // this.diskon = this.diskon + (parseInt(i.harga_fix *((i.diskon)/100)) * i.qty);
                // console.log(this.diskon);
                harga_fix = harga_fix + (parseInt(i.harga_fix *((100-i.diskon)/100)) * i.qty)
            });
            }
            return harga_fix;
        },
        cekloghutang() {
            var harga_fix = 0;
        if(this.print_value.log_hutang){
                    this.print_value.log_hutang.forEach(i => {

                        harga_fix = harga_fix + (i.bayar)
                    });
        }
            return harga_fix;
        }
    },
}
</script>

<style>
* {
    font-size: 10px !important;
    font-family: 'arial' !important;
    line-height: normal !important;
    /* font-weight:normal; */
}

body {
    color: #000000 !important;
    padding-top: 10px !important;

}

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 95px;
    max-width: 95px;
}

td.quantity,
th.quantity {
    width: 26px;
    max-width: 26px;
    font-size: 9.3px !important;
    word-break: break-all;
}

td.price1,
th.price1 {
    width: 75px;
    max-width: 75px;
    font-size: 9.3px !important;
    word-break: break-all;
}

td.price,
th.price {
    width: 75px;
    max-width: 75px;
    font-size: 9.3px !important;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    /* margin-left: 4px; */
    width: 170px;
    max-width: 170px;

}

img {
    max-width: inherit;
    width: inherit;
}

@media print {

    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
</style>
